<template>
  <v-row v-if="statusMatching[application.status_id].type == 'event'">
    <v-col v-if="eventStatusLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </v-col>
    <v-col cols="12" v-if="statusEvent && statusEvent.showDateStart">
      <v-card rounded="xl">
        <v-card-title class="bg-indigo">
          <v-row>
            <v-col align="left">{{ statusEvent.summary }}</v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-subtitle class="ma-4"
          >{{ statusEvent.showDateStart.split(" ")[0] }}
          {{ statusEvent.showDateStart.split(" ")[1] }} -
          {{ statusEvent.showDateEnd.split(" ")[1] }}</v-card-subtitle
        >
        <v-card-text class="mx-2 my-n4">
          <v-select
            label="Terminstatus"
            :items="$store.state.meetingStatuses"
            item-title="label"
            item-value="value"
            density="compact"
            v-model="application.meeting_status"
            @update:modelValue="updateApplication(application, statusEvent)"
          ></v-select>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" v-else-if="eventStatusLoading == false">
      <v-select
        label="Terminstatus"
        density="compact"
        :items="$store.state.meetingStatuses"
        item-title="label"
        item-value="value"
        v-model="application.meeting_status"
        @update:modelValue="updateApplication(application)"
      ></v-select
    ></v-col>
  </v-row>
  <v-row class="mx-2" v-if="application.status_id == 1">
    <v-col cols="12" align="center">
      <v-btn color="error" class="mr-4" @click="moveToNextStatus(application)"
        ><v-icon>mdi-thumb-down</v-icon></v-btn
      >
      <v-btn
        color="success"
        :disabled="application.rating < 2 || !application.rating"
        @click="moveToNextStatus(application)"
        ><v-icon>mdi-thumb-up</v-icon></v-btn
      >
    </v-col>
  </v-row>
  <v-row
    class="mx-2"
    v-if="
      (application.status_id == 9 ||
        application.status_id == 4 ||
        application.status_id == 6 ||
        application.status_id == 14) &&
      application.rating > 1
    "
  >
    <v-col
      cols="12"
      class="my-n1"
      v-for="step in statusMatching[application.status_id].nextStepArray"
      align="center"
    >
      <v-btn
        v-if="step.status_id"
        size="small"
        rounded="xl"
        :color="step.color"
        v-bind="props"
        @click="moveToNextStatus(application, step)"
      >
        <v-icon class="mr-1">{{ step.icon }}</v-icon>
        {{ step.action }}
      </v-btn>
    </v-col>
  </v-row>
  <v-row class="mx-2" v-if="application.status_id == 2">
    <v-col align="center">
      <v-btn color="success" @click="moveToNextStatus(application)"
        >Nach Gespräch offen</v-btn
      >
    </v-col>
  </v-row>
  <v-row class="mx-2" v-if="application.status_id == 3">
    <v-col cols="8">
      <v-select
        label="Hiring Hours"
        density="compact"
        v-model="statusMatching[application.status_id].chosenEvent"
        :items="statusMatching[application.status_id].events"
        item-title="selectionItem"
        return-object
      ></v-select>
    </v-col>
    <v-col cols="4" class="mt-2" align="center">
      <v-btn
        color="success"
        :disabled="!statusMatching[application.status_id].chosenEvent"
        @click="addAttendee(statusMatching[application.status_id].chosenEvent)"
        :loading="eventLoading"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn> </v-col
    ><v-col align="center" class="mt-n4">
      <v-btn color="success" @click="moveToNextStatus(application)"
        >Nach Gespräch offen</v-btn
      >
    </v-col></v-row
  >
  <v-row
    class="mx-2"
    v-if="application.status_id == 5 || application.status_id == 19"
  >
    <v-col cols="8">
      <v-select
        :label="
          application.status_id == 19
            ? 'Basis Seminar 1 Tag - Online'
            : 'Basis Seminar'
        "
        v-model="statusMatching[application.status_id].chosenEvent"
        :items="statusMatching[application.status_id].events"
        item-title="selectionItem"
        density="compact"
        return-object
      ></v-select>
    </v-col>
    <v-col cols="4" class="mt-2" align="center">
      <v-btn
        color="success"
        :disabled="!statusMatching[application.status_id].chosenEvent"
        @click="addAttendee(statusMatching[application.status_id].chosenEvent)"
        :loading="eventLoading"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn> </v-col
    ><v-col align="center" class="mt-n4">
      <v-btn color="success" @click="moveToNextStatus(application)"
        >Bereit für Test</v-btn
      >
    </v-col></v-row
  >
  <v-row class="mx-2" v-if="application.status_id == 6">
    <v-col align="center">
      <v-btn color="success" @click="moveToNextStatus(application)"
        >Bereit für Test</v-btn
      >
    </v-col>
  </v-row>
  <v-row class="mx-2" v-if="application.status_id == 11">
    <v-col align="center">
      <v-btn
        :loading="loading"
        color="error"
        @click="testFailed(application)"
        class="mr-1"
        >Test nicht bestanden</v-btn
      >
      <v-btn color="success" @click="moveToNextStatus(application)"
        >Test bestanden</v-btn
      ></v-col
    ></v-row
  >
  <v-row class="mx-2" v-if="application.status_id == 12">
    <v-col align="center">
      <v-select
        :items="onboardings"
        item-title="name"
        return-object
        v-model="candidateInfo.onboarding"
        label="Onboardings"
      ></v-select>
    </v-col>
    <v-col cols="6" align="center">
      <v-btn
        :disabled="!candidateInfo.onboarding"
        :loading="loading"
        color="success"
        @click="
          moveToNextStatus(application);
          createEmployee();
        "
        >Einstellen</v-btn
      >
    </v-col></v-row
  >
  <v-row class="mx-2" v-if="application.status_id == 10">
    <v-col cols="12" align="center">
      <v-btn color="success" @click="reactivateCandidate(application)"
        >Reaktivieren</v-btn
      >
    </v-col>
  </v-row>
  <v-row class="mx-2" v-if="application.status_id == 15">
    <v-col cols="12" align="center">
      <div v-if="candidateInfo.employees.length > 0">
        <div>Als Mitarbeiter gespeichert</div>
        <v-btn
          rounded="lg"
          variant="outlined"
          :href="`/Mitarbeiter/${candidateInfo.employees[0].id}`"
          target="_self"
          >Mitarbeiterprofil</v-btn
        >
      </div>
    </v-col></v-row
  >
  <v-col cols="12" v-if="$store.state.applicationViews.reminder">
    <AvailabilityCheck
      v-if="application.status_id != 15"
      :candidateInfo="candidateInfo"
      :application="application"
    ></AvailabilityCheck>
    <ComponentReminder
      v-if="application.status_id != 15"
      :candidateInfo="candidateInfo"
      :application="application"
    ></ComponentReminder>
  </v-col>
</template>

<script setup>
import ComponentReminder from "./ComponentReminder.vue";
import AvailabilityCheck from "./AvailablityCheck.vue";
import axios from "axios";
const props = defineProps({
  candidateInfo: Object,
  emailObject: Object,
  application: Object,
});
</script>

<script>
export default {
  data() {
    return {
      loading: false,
      onboardings: [],
      statusEvent: null,
      eventLoading: false,
      eventStatusLoading: false,
      statusMatching: {
        1: {
          activityId: 2,
          nextStatusId: 9,
          action: "Bewertet",
        },
        17: {},
        9: {
          nextStepArray: [
            {
              label: "HH",
              icon: "mdi-account-group",
              status_id: 3,
              color: "blue-darken-3",
              action: "Hiring Hour",
              activityId: 3,
            },
            {
              label: "VG",
              icon: "mdi-account-multiple",
              value: "jobInterview",
              status_id: 2,
              color: "yellow-darken-4",
              action: "Vorstellungsgespräch",
              activityId: 4,
            },
          ],
        },
        2: {
          activityId: 11,
          nextStatusId: 4,
          eventName: "Vorstellungsgespräch",
          action: "Nach Gespräch offen",
          type: "event",
        },
        3: {
          activityId: 11,
          nextStatusId: 4,
          eventName: "Hiring Hour",
          action: "Nach Gespräch offen",
          type: "event",
          templateId: 45,
          events: [],
          chosenEvent: null,
        },
        4: {
          nextStepArray: [
            {
              label: "BS",
              icon: "mdi-school",
              status_id: 5,
              activityId: 6,
              color: "blue-darken-3",
              action: "Basis Seminar 2 Tage",
            },
            {
              label: "QS",
              icon: "mdi-trophy",
              status_id: 19,
              activityId: 15,
              color: "blue-darken-3",
              action: "Basis Seminar 1 Tag",
            },
            {
              label: "KU",
              icon: "mdi-earth",
              status_id: 6,
              activityId: 5,
              color: "yellow-darken-4",
              action: "Keeunit",
            },
            /*
            {
              label: "SN",
              icon: "mdi-domain",
              status_id: 14,
              activityId: 13,
              color: "lime-darken-4",
              action: "Schnuppernacht",
            },
            */
          ],
        },
        5: {
          activityId: 7,
          nextStatusId: 11,
          eventName: "Basis Seminar - 2 Tage - Online",
          action: "Bereit für Test",
          type: "event",
          templateId: 49,
          events: [],
          chosenEvent: null,
          day2: true,
        },
        19: {
          activityId: 7,
          nextStatusId: 11,
          eventName: "Basis Seminar 1 Tag - Online",
          action: "Bereit für Test",
          type: "event",
          templateId: 56,
          events: [],
          chosenEvent: null,
        },
        6: {
          activityId: 7,
          nextStatusId: 11,
          action: "Bereit für Test",
          type: "event",
        },
        14: {
          activityId: 11,
          nextStatusId: 4,
          eventName: "Schnuppernacht",
          action: "Nach Gespräch offen",
          type: "event",
          nextStepArray: [
            {
              action: "Basis Seminar Theorie",
              status_id: 5,
              icon: "mdi-school",
              color: "green-lighten-1",
              activityId: 6,
            },
            {
              action: "Keeunit",
              status_id: 6,
              icon: "mdi-earth",
              color: "green-lighten-1",
              activityId: 5,
            },
            {
              action: "Bereit für Prüfung",
              status_id: 11,
              icon: "mdi-check",
              color: "green-darken-3",
              activityId: 7,
            },
          ],
        },
        11: {
          activityId: 8,
          nextStatusId: 12,
          eventName: "Basis Seminar Prüfung - Vor Ort",
          action: "Test bestanden",
          type: "event",
        },
        12: {
          activityId: 14,
          nextStatusId: 15,
          type: "event",
          action: "Eingestellt",
          eventName: "Vertragsgespräch - Vor Ort",
        },
        15: {
          action: "Als Mitarbeiter erstellt",
        },
        10: {
          activityId: 19,
          nextStatusId: 1,
          action: "Reaktivieren",
        },
        16: {},
        18: {},
        13: {},
      },
    };
  },
  methods: {
    async getRelevantEvents() {
      this.eventStatusLoading = true;
      this.statusMatching[this.application.status_id].events = [];
      var response = await this.$store.dispatch("checkCalendar", {});
      for (var i = 0; i < response.length; i++) {
        var item = response[i];
        if (
          item.summary.indexOf(
            this.statusMatching[this.application.status_id].eventName,
          ) > -1
        ) {
          item.attendees = item.attendees ? item.attendees : [];
          item.selectionItem = `${item.showDateStart} (${item.attendees.length} Teilnehmer)`;
          this.statusMatching[this.application.status_id].events.push(item);
        }
      }
      for (var i = 0; i < this.candidateInfo.events.length; i++) {
        var event = this.candidateInfo.events[i];
        if (
          event.summary ==
          this.statusMatching[this.application.status_id].eventName
        ) {
          this.statusEvent = event;
        }
      }
      this.eventStatusLoading = false;
    },
    async updateApplication(application, statusEvent) {
      this.loading = true;

      var queryObject = {
        table: "recruiting_job_candidates",
        id: application.id,
        payload: {
          meeting_status: application.meeting_status,
        },
      };
      this.$store.dispatch("updateSupabaseEntity", queryObject);
      if (statusEvent) {
        var action = `Terminstatus für ${statusEvent.summary} auf ${
          this.$store.state.meetingStatusObject[this.application.meeting_status]
            .label
        } gesetzt!`;
        var text = `Termin ist ${statusEvent.summary} von ${statusEvent.showDateStart} bis ${statusEvent.showDateEnd}`;
      } else {
        var action = `Terminstatus  auf ${
          this.$store.state.meetingStatusObject[this.application.meeting_status]
            .label
        } gesetzt!`;
        var text = null;
      }
      await this.$store.dispatch("createSupabaseEntity", {
        table: "notes",
        payload: {
          job_candidate_id: this.application.id,
          owner_id: this.$store.state.userInfo.id,
          action: action,
          text: text,
        },
      });
      this.$store.state.candidateChangeCheck = true;
      this.loading = false;
    },
    async addAttendee(event) {
      this.eventLoading = true;
      event.attendees.push({
        email: this.candidateInfo.email,
      });
      event.start = event.start.dateTime;
      event.end = event.end.dateTime;
      await this.$store.dispatch("updateEvent", event);
      this.application.meeting_status = "invitationSend";
      var queryObject = {
        table: "recruiting_job_candidates",
        id: this.application.id,
        payload: {
          meeting_status: this.application.meeting_status,
        },
      };
      this.$store.dispatch("updateSupabaseEntity", queryObject);
      if (this.statusMatching[this.application.status_id].day2) {
        await this.getDay2(event);
      }
      await this.sendAttendeeEmail(event);
      await this.addInvitationNote();
      this.$store.state.candidateChangeCheck = true;
      this.eventLoading = false;
    },
    async getDay2(event) {
      var correctDate = new Date(event.start);
      correctDate.setDate(correctDate.getDate() + 1);
      var formatedCorrectDate = await this.$store.dispatch(
        "formatDate",
        correctDate,
      );

      var events = await this.$store.dispatch("checkCalendar", {});
      var day2Event = events.find(
        (item) =>
          item.summary === "Basis Seminar - Online - Tag 2" &&
          formatedCorrectDate.split(" ")[0] === item.showDateEnd.split(" ")[0],
      );
      day2Event.attendees = day2Event.attendees ? day2Event.attendees : [];
      day2Event.attendees.push({
        email: this.candidateInfo.email,
      });
      day2Event.start = day2Event.start.dateTime;
      day2Event.end = day2Event.end.dateTime;
      await this.$store.dispatch("updateEvent", day2Event);

      this.candidateInfo.date2 = formatedCorrectDate.split(" ")[0];
      this.candidateInfo.day2 = this.$store.state.days[correctDate.getDay()];
    },
    async getTemplateFiles(templateId) {
      var files = [];
      var response = await this.$store.dispatch("getStorageFolderFiles", {
        bucket: "attachments",
        folder: templateId,
      });
      if (response.data.length > 0) {
        for (var i = 0; i < response.data.length; i++) {
          var blob = await this.$store.dispatch("downloadStorageBlob", {
            bucket: "attachments",
            file: `${templateId}/${response.data[i].name}`,
          });
          var file = {
            mimeType: blob.data.type,
            fileName: response.data[i].name,
            data: blob.data,
          };
          files.push(file);
        }
      }
      return files;
    },
    async sendAttendeeEmail(event) {
      var queryObject = {
        table: "recruiting_templates",
        where: [
          {
            type: "eq",
            key: "id",
            value: this.statusMatching[this.application.status_id].templateId,
          },
        ],
      };
      var templateResponse = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      var template = templateResponse.data[0];
      var timeDate = event.showDateStart;
      var timeDateStart = event.showDateStart;
      var timeDateEnd = event.showDateEnd;
      var date = timeDate.split(" ")[0];
      var dateDB = new Date(
        date.split(".")[2],
        date.split(".")[1] - 1,
        date.split(".")[0],
      );
      this.candidateInfo.date = date;
      this.candidateInfo.time = timeDate.split(" ")[1];
      this.candidateInfo.timeStart = timeDateStart.split(" ")[1];
      this.candidateInfo.timeEnd = timeDateEnd.split(" ")[1];
      this.candidateInfo.day = this.$store.state.days[dateDB.getDay()];
      this.candidateInfo.eventDetails = event;
      var subject = await this.$store.dispatch("replaceBody", {
        body: template.subject,
        info: this.candidateInfo,
      });
      var body = await this.$store.dispatch("replaceBody", {
        body: template.body,
        info: this.candidateInfo,
      });
      var files = await this.getTemplateFiles(template.id);
      var emailObject = {
        from: "bewerbung@bellcaptain.de",
        to: this.candidateInfo.email,
        subject: subject,
        body: body,
        files: files,
      };
      await this.$store.dispatch("sendGmail", emailObject);
    },
    async addInvitationNote() {
      var eventName = this.statusMatching[this.application.status_id].eventName;
      await this.$store.dispatch("createSupabaseEntity", {
        table: "notes",
        payload: {
          job_candidate_id: this.application.id,
          owner_id: this.$store.state.userInfo.id,
          action: `Für ${eventName} am ${this.candidateInfo.date} eingeladen!`,
        },
      });
    },
    async testFailed(application) {
      this.loading = true;
      this.application.meeting_status = "testFailed";
      var queryObject = {
        table: "recruiting_job_candidates",
        id: application.id,
        payload: {
          meeting_status: application.meeting_status,
        },
      };
      this.$store.dispatch("updateSupabaseEntity", queryObject);
      await this.$store.dispatch("createSupabaseEntity", {
        table: "notes",
        payload: {
          job_candidate_id: this.application.id,
          owner_id: this.$store.state.userInfo.id,
          action: `Test nicht bestanden!`,
        },
      });
      this.loading = false;
      this.$store.state.candidateChangeCheck = true;
    },
    async moveToNextStatus(application, step) {
      if (step) {
        var nextStatusId = step.status_id;
        var activityId = step.activityId;
        var action = step.action;
      } else {
        var nextStatusId =
          this.statusMatching[this.application.status_id].nextStatusId;
        var activityId =
          this.statusMatching[this.application.status_id].activityId;
        var action = this.statusMatching[this.application.status_id].action;
      }
      this.application.status_id = nextStatusId;
      this.application.meeting_status = null;
      var payload = JSON.parse(JSON.stringify(application));
      delete payload.recruiting_status;
      delete payload.rating;
      payload.reminder_date = new Date().toISOString();
      payload.modified_at = new Date().toISOString();
      payload.status_change_date = new Date().toISOString();
      payload.reminder_count = 0;
      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "recruiting_job_candidates",
        payload: payload,
      });
      var notePayload = {
        job_candidate_id: payload.id,
        action: action,
        owner_id: this.$store.state.userInfo.id,
        activity_id: activityId,
      };
      await this.$store.dispatch("createSupabaseEntity", {
        table: "notes",
        payload: notePayload,
      });
      this.$store.state.candidateChangeCheck = true;
      if (this.statusMatching[application.status_id].type == "event") {
        this.$store.state.applicationViews.actions[2].disabled = false;
      } else {
        this.$store.state.applicationViews.actions[2].disabled = true;
      }
      this.getRelevantEvents();
    },
    async reactivateCandidate(application) {
      await this.$store.dispatch("updateSupabaseEntity", {
        table: "recruiting_job_candidates",
        id: application.id,
        payload: {
          status_id: 1,
        },
      });
      await this.$store.dispatch("createSupabaseEntity", {
        table: "notes",
        payload: {
          job_candidate_id: application.id,
          owner_id: this.$store.state.userInfo.id,
          action: `Kandidat reaktiviert!`,
          activity_id: 19,
        },
      });
      application.status_id = 1;
    },
    async createEmployee() {
      this.loading = true;
      var fullName =
        this.candidateInfo.first_name + " " + this.candidateInfo.last_name;

      var publicUserObject = {
        first_name: this.candidateInfo.first_name,
        last_name: this.candidateInfo.last_name,
        email: this.candidateInfo.email,
        full_name: fullName,
        active: true,
        candidate_id: this.candidateInfo.id,
      };

      var publicUserId = await this.$store.dispatch(
        "createUserInSystem",
        publicUserObject,
      );

      var employeeObject = {
        first_name: this.candidateInfo.first_name,
        last_name: this.candidateInfo.last_name,
        email: this.candidateInfo.email,
        name:
          this.candidateInfo.first_name + " " + this.candidateInfo.last_name,
        status: "Aktiv",
        user_id: publicUserId,
        candidate_id: this.candidateInfo.id,
        onboarding:
          this.candidateInfo.onboarding.id > 0
            ? this.candidateInfo.onboarding
            : null,
      };
      var queryObject = {
        table: "employees",
        payload: employeeObject,
      };
      var employeeId = await this.$store.dispatch(
        "createSupabaseEntity",
        queryObject,
      );

      employeeObject.id = employeeId;

      var payload = [];
      var synchroKeys = [
        { key: "first_name", key_id: 2, type: "value_text" },
        { key: "last_name", key_id: 3, type: "value_text" },
        { key: "email", key_id: 17, type: "value_text" },
        { key: "gender", key_id: 4, type: "value_text" },
        { key: "phone", key_id: 9, type: "value_text" },
        { key: "birth_date", key_id: 16, type: "value_date" },
        { key: "nationality", key_id: 18, type: "value_text" },
        { key: "city", key_id: 15, type: "value_text" },
      ];
      synchroKeys.forEach((item) => {
        var object = {
          employee_id: employeeId,
          key_id: item.key_id,
        };
        var value = this.candidateInfo[item.key];
        if (item.key == "gender") {
          value = value ? "Männlich" : "Weiblich";
        }
        object[item.type] = value;
        payload.push(object);
      });

      payload.push({
        employee_id: employeeId,
        key_id: 21,
        value_text: "Aktiv",
      });
      payload.push({
        employee_id: employeeId,
        key_id: 1,
        value_text: fullName,
      });
      if (this.candidateInfo.onboarding.id > 0) {
        payload.push({
          employee_id: employeeId,
          key_id: 69,
          value_text: "Ja",
        });
      }

      var employeeKeyMatching = {
        table: "employee_data_matching",
        payload: payload,
      };
      this.candidateInfo.employees = [employeeObject];
      await this.$store.dispatch("createSupabaseEntity", employeeKeyMatching);
      this.loading = false;
    },
    async getOnboarding() {
      var queryObject = {
        select:
          "*,employee_onboarding_offboarding_process_step_matching(*,employee_onboarding_offboarding_steps(*,employee_onboarding_offboarding_step_components(*)))",
        table: "employee_onboarding_offboarding_process",
        where: [
          { type:"is", key: "is_offboarding", value: false}
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach((item) => {
        item.employee_onboarding_offboarding_process_step_matching.forEach((item) => {
          item.employee_onboarding_offboarding_steps.employee_onboarding_offboarding_step_components.sort(
            (a, b) => a.order - b.order,
          );
        });
        item.employee_onboarding_offboarding_process_step_matching.sort(
          (a, b) => a.order - b.order,
        );
      });
      response.data.push({
        name: "Kein Onboarding",
        id: 0,
        employee_onboarding_offboarding_process_step_matching: [],
      });
      this.onboardings = response.data;
    },
    openPhoneLink(phone) {
      const phoneLink = `tel:${phone}`;
      window.open(phoneLink);
      this.process.contacted = true;
    },
  },
  async created() {
    this.getRelevantEvents();
    this.getOnboarding();
  },
};
</script>
