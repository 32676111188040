<template>
  <div>
    <h1>Shifts</h1>
    <v-card class="ma-4">
      <v-card-text>
        <v-row>
          <v-btn
            @click="
              city.chosen = !city.chosen;
              getShifts();
            "
            v-for="city in cities"
            rounded="lg"
            class="ma-1"
            :color="city.chosen ? 'success' : null"
          >
            {{ city.city }}
          </v-btn>

          <v-col cols="6">
            <v-checkbox
              label="Einarbeitung"
              @change="
                training = !training;
                getShifts();
              "
            ></v-checkbox>
          </v-col>
          <v-col cols="6" class="mt-2">
            <v-text-field
              @input="getShifts()"
              min="0"
              variant="outlined"
              type="number"
              v-model="dayRange"
              label="Tagesrange"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-autocomplete
              variant="outlined"
              clearable
              chips
              @update:model-value="getShifts('hotels', $event)"
              v-model="chosenHotels"
              multiple
              label="Hotel"
              :items="hotels"
              item-title="name"
              item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              variant="outlined"
              clearable
              chips
              @update:model-value="getShifts('employees', $event)"
              v-model="chosenEmployees"
              multiple
              label="Mitarbeiter"
              :items="employees"
              item-title="name"
              item-value="id"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table-virtual
      :loading="loading"
      :headers="headers"
      :items="shifts"
      :items-per-page="-1"
      hide-default-footer
      class="elevation-1"
      v-if="dayRange > 0"
    >
      <template v-slot:item.employee="{ item }">
        <v-btn
          v-if="item.employee"
          size="small"
          variant="text"
          :to="'/Mitarbeiter/' + item.employee.id"
          class="text-decoration-underline"
          >{{
            item.employee?.first_name + " " + item.employee?.last_name
          }}</v-btn
        >
        <v-alert v-else type="warning"
          >Nicht gematcht mit Mitarbeiter!!!</v-alert
        >
      </template>
      <template
        v-for="n in parseInt(dayRange, 10) * 2 + 1"
        v-slot:[`item.${n}`]="{ item, value }"
      >
        <v-chip size="small" color="blue" variant="elevated" v-if="value"
          >{{ value?.hotels.short_name }}
          <v-icon v-if="value?.is_training">mdi-account-school</v-icon>
          <span v-if="value?.is_senior == false">/Clerk</span>
        </v-chip>
        <v-btn
          @click="
            openPerformanceRecord(value, value?.hotel_performance_records[0])
          "
          color="green"
          rounded="xl"
          size="small"
          v-if="value?.hotel_performance_records.length > 0"
        >
          <v-icon
            :icon="
              value?.hotel_performance_records[0].is_signed
                ? 'mdi-file-document-check'
                : 'mdi-file-document'
            "
          ></v-icon>
        </v-btn>
      </template>
    </v-data-table-virtual>
  </div>
  <v-dialog v-model="performanceRecord.dialog">
    <template #default="{ attrs }">
      <v-toolbar color="indigo">
        <v-toolbar-title>Leistungsnachweis</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="performanceRecord.dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="dialog-container" style="overflow-y: auto">
        <ShiftRecord
          :reportId="performanceRecord.reportId"
          :shift="performanceRecord.shift"
        ></ShiftRecord>
      </div>
    </template>
  </v-dialog>
</template>

<script setup>
import ShiftRecord from "@/components/Shifts/PerformanceRecord.vue";
</script>
<script>
export default {
  data() {
    return {
      start: null,
      shifts: [],
      employeeIds: {},
      cities: [],
      hotels: [],
      chosenHotels: [],
      chosenEmployees: [],
      employees: [],
      training: false,
      loading: false,
      dayRange: 7,
      performanceRecord: {
        dialog: false,
        reportId: null,
        shift: null,
      },
      headers: [
        {
          title: "Mitarbeiter",
          value: "employee",
        },
      ],
    };
  },
  methods: {
    openProfile(item) {
      this.updateEmployeeId(item.id);
    },
    async getHotels() {
      const response = await this.$store.dispatch("getSupabaseEntity", {
        table: "hotels",
        where: [{ type: "is", key: "email", value: true }],
      });

      this.hotels = response.data;
    },
    async getEmployees() {
      const response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employees",
        where: [{ type: "eq", key: "status", value: "Aktiv" }],
      });
      response.data.forEach((employee) => {
        this.employeeIds[employee.id] = employee;
      });

      this.employees = response.data;

      this.shifts.forEach((shift) => {
        shift.employee = this.employeeIds[shift.employee]
          ? this.employeeIds[shift.employee]
          : shift.employee;
      });
    },
    async getShifts(type, event) {
      this.start = new Date();
      var startDate = new Date();
      let endDate = new Date();
      if (this.dayRange > 0) {
        let range = parseInt(this.dayRange, 10);

        if (this.start.getHours() < 13) {
          this.start.setDate(this.start.getDate() - 1);
        }
        if (type == "hotels") {
          this.chosenHotels = event;
        }
        if (type == "employees") {
          this.chosenEmployees = event;
        }
        this.headers = [
          {
            title: "Mitarbeiter",
            value: "employee",
            width: "10%",
          },
        ];
        this.shifts = [];
        let dayPlus = 0;
        startDate.setDate(startDate.getDate() - range);
        endDate.setDate(endDate.getDate() + range);

        let cityArray = this.cities
          .filter((city) => city.chosen)
          .map((city) => city.id);

        var queryObject = {
          table: "shifts",
          select:
            "*,employees(name),hotel_performance_records(*),hotels!inner(name,short_name,cities!inner(city))",
          where: [
            {
              type: "gte",
              key: "date",
              value: startDate.toISOString(),
            },
            {
              type: "lte",
              key: "date",
              value: endDate.toISOString(),
            },
            {
              type: "in",
              key: "hotels.cities.id",
              value: cityArray,
            },
            { type: "is", key: "is_booked", value: true },
          ],
        };

        if (this.training) {
          queryObject.where.push({
            type: "eq",
            key: "is_training",
            value: true,
          });
        }
        if (this.chosenHotels.length > 0) {
          queryObject.where.push({
            type: "in",
            key: "hotel_id",
            value: this.chosenHotels,
          });
        }
        if (this.chosenEmployees.length > 0) {
          queryObject.where.push({
            type: "in",
            key: "employee_id",
            value: this.chosenEmployees,
          });
        }
        const response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        let headerLength = 0;
        if (range > 0) {
          headerLength = range + range + 1;
        }

        let headerRange = {};
        var dateForHeader = new Date(endDate);
        for (let i = 0; i < headerLength; i++) {
          this.headers.push({
            title: dateForHeader.toLocaleDateString(),
            value: (i + 1).toString(),
            align: "center",
          });
          headerRange[dateForHeader.toISOString().split("T")[0]] = (
            i + 1
          ).toString();
          dateForHeader.setDate(dateForHeader.getDate() - 1);
        }

        let allEmployees = response.data.map((shift) => shift.employee_id);
        let uniqueEmployees = [...new Set(allEmployees)];
        if (headerLength > 0) {
          uniqueEmployees.forEach((employee) => {
            let employeeShifts = response.data.filter(
              (shift) => shift.employee_id == employee,
            );
            let shiftObject = {
              employee: employee,
            };
            employeeShifts.forEach((shift) => {
              let shiftName;
              if (shift.hotels) {
                shiftName = shift.hotels.short_name;
                if (shift.is_training) {
                  shiftName += " (Einarbeitung)";
                }
              }
              var index = headerRange[shift.date].toString();
              shiftObject[index] = shift;
            });
            this.shifts.push(shiftObject);
          });
        }

        this.shifts.forEach((shift) => {
          shift.employee = this.employeeIds[shift.employee]
            ? this.employeeIds[shift.employee]
            : shift.employee;
        });
      }
    },
    openPerformanceRecord(shift, record) {
      this.performanceRecord.shift = shift;
      this.performanceRecord.reportId = record.id;
      this.performanceRecord.dialog = true;
    },
  },
  async mounted() {
    this.loading = true;
    const cityResponse = await this.$store.dispatch("getSupabaseEntity", {
      table: "cities",
      where: [{ type: "eq", key: "hotel_city", value: true }],
    });
    cityResponse.data?.forEach((city) => {
      city.chosen = true;
    });
    this.cities = cityResponse.data;
    await this.getHotels();
    await this.getShifts();
    await this.getEmployees();
    this.loading = false;
  },
};
</script>
