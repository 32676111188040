<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card elevation="10">
          <v-card-text>
            <v-row>
              <v-col cols="3" align="left">
                <v-btn @click="changeDays('minus')">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" align="center">
                <div class="text-h5 my-2">
                  <v-chip class="mb-1" color="indigo-darken-4">{{
                    "KW " + getISOWeek(weekStartDate)
                  }}</v-chip>
                  Schichtplan für Woche
                  {{
                    weekStartDate.toLocaleDateString() +
                    " - " +
                    weekEndDate.toLocaleDateString()
                  }}
                </div>
              </v-col>
              <v-col cols="3" align="right">
                <v-btn @click="changeDays('plus')">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="2">
                <v-select
                  :items="cities"
                  label="Stadt"
                  v-model="chosenCity"
                  item-value="id"
                  item-title="city"
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  clearable
                  :items="cityHotels[chosenCity]"
                  label="Hotel"
                  v-model="chosenHotel"
                  item-value="id"
                  item-title="name"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="chosenEmployee"
                  label="Mitarbeiter"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn-toggle v-model="eventFilter">
                  <v-btn
                    size="small"
                    v-for="event in events"
                    :key="event.name"
                    :value="event.value"
                    @click="getEmployees"
                  >
                    <v-icon>{{ event.icon }}</v-icon>
                    {{ event.name }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="2">
                  <v-btn :color="chosenLevel == level ? 'success' : null" v-for="level in ['Senior','Clerk','Alle']" @click="chosenLevel = level;" :key="level" :value="level">{{ level }}</v-btn>
                </v-col>
              <v-col cols="12" class="mt-n8">
                <v-card>
                  <v-card-text v-if="tableLoading">
                    <v-progress-linear
                      indeterminate
                      color="primary"
                    ></v-progress-linear>
                  </v-card-text>
                  <v-card-text
                    v-else
                    style="overflow: auto; width: 100%"
                    class="tableFixHead"
                  >
                  <v-switch
                    class="mb-n7"
                    v-model="preciseTable"
                    label="Ausführlicher Plan"
                  ></v-switch>
                    <table
                      v-if="preciseTable"
                      style="table-layout: fixed; width: 100%"
                    >
                      <thead>
                        <tr>
                          <th
                            v-for="(header, n) in headers"
                            style="font-size: 6pt"
                            :class="n == 0 ? 'sticky-col' : ''"
                            :style="{
                              border: '4px solid black',
                              borderRight:
                                n == 10 || n == 17 ? '8px black solid' : null,
                              borderLeft:
                                new Date(header.date).getDay() == 1
                                  ? '8px solid black'
                                  : null,
                            }"
                          >
                            {{ header.title }}
                          </th>
                        </tr>
                      </thead>
                      <colgroup>
                        <col style="width: 150px" />
                        <col
                          span="1"
                          v-for="header in headers"
                          style="width: 50px"
                          :style="
                            new Date(header.date).getDay() == 6 ||
                            new Date(header.date).getDay() == 5
                              ? 'background-color: #D6EEEE'
                              : null
                          "
                        />
                      </colgroup>
                      <tr v-for="employee in employees">
                        <td style="border: 4px solid black" class="sticky-col">
                          <span
                            class="d-inline-block text-truncate"
                            style="max-width: 150px"
                            >{{ employee.show_name }}</span
                          >
                        </td>
                        <td
                          @click="editEvent(employee, employee[n], n)"
                          v-for="n in dayRange"
                          :style="{
                            cursor: 'pointer',
                            borderBottom: '2px black solid',
                            borderRight:
                              n == 10 || n == 17 ? '8px black solid' : null,
                            borderLeft:
                              n == 4 || n == 11 || n == 18 || n == 25 || n == 32
                                ? '6px solid black'
                                : null,
                            backgroundColor:
                              employee[n].addedEvents &&
                              employee[n].addedEvents[0]
                                ? employee[n].addedEvents[0].color
                                : employee[n].color,
                          }"
                        >
                          <v-chip
                            v-if="employee[n] && employee[n].is_shift"
                            variant="text"
                            class="mr-1"
                            size="x-small"
                          >
                            {{ employee[n].event }}
                            <v-icon
                              class="ml-1"
                              size="x-small"
                              v-for="icon in employee[n].addedEvents"
                              :icon="icon.icon"
                            ></v-icon>
                          </v-chip>
                          <v-icon
                            v-else-if="employee[n]"
                            size="x-small"
                            :icon="employee[n].icon"
                          ></v-icon>
                        </td>
                      </tr>
                    </table>
                    <v-data-table
                      v-else
                      items-per-page="-1"
                      fixed-header
                      :loading="tableLoading"
                      :search="search"
                      :headers="headers"
                      :items="employees"
                      hide-default-footer
                      style="border: 2px solid black; width: 100%"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.show_name }}</td>
                          <td
                            @click="editEvent(item, item[n], n)"
                            v-for="n in dayRange"
                            :style="{
                              cursor: 'pointer',

                              borderRight:
                                n == 3 || n == 10 ? '6px black solid' : null,
                              backgroundColor:
                                item[n].addedEvents && item[n].addedEvents[0]
                                  ? item[n].addedEvents[0].color
                                  : item[n].color,
                            }"
                          >
                            <v-chip
                              v-if="item[n].is_shift"
                              variant="outlined"
                              size="small"
                            >
                              {{ item[n].event }}
                              <v-icon
                                class="ml-1"
                                size="small"
                                :color="item[n].color"
                                v-for="icon in item[n].addedEvents"
                                :icon="icon.icon"
                                >{{ item[n] }} {{ icon.icon }}</v-icon
                              >
                            </v-chip>
                            <v-icon v-else :icon="item[n].icon"></v-icon>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="eventEdit.dialog" width="1000">
      <v-card>
        
        <v-card-title>
          <v-row>
            <v-col>Einträge erstellen</v-col>
            <v-col cols="4" align="right">
              <v-btn size="x-large" flat @click="eventEdit.dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-date-picker
                v-model="eventEdit.eventData.dateRange"
                multiple
                @update:model-value="limitDateRange"
                show-adjacent-months
                first-day-of-week="1"
                hide-header
                :min="eventEdit.eventData.formattedDate"
              ></v-date-picker>
            </v-col>
            <v-col cols="6" class="mb-n6">
              <v-autocomplete
                :items="employees"
                v-model="eventEdit.eventData.employee_id"
                item-title="show_name"
                item-value="id"
                disabled
                variant="solo"
                label="Mitarbeiter"
              ></v-autocomplete>
              <div>Ereignis</div>
              <v-btn
                size="small"
                :color="eventEdit.eventData[event.value] ? 'success' : null"
                v-for="event in events"
                rounded="xl"
                class="ma-1"
                :disabled="event.disabled"
                :prepend-icon="event.icon"
                @click="
                  chooseEvent(eventEdit.eventData, event, { added: false })
                "
                >{{ event.name }}</v-btn
              >
              <div class="mt-4">Zusätzliche Ereignisse</div>
              <v-btn
                size="small"
                rounded="xl"
                class="ma-1"
                :disabled="true"
                :color="eventEdit.eventData[event.value] ? 'success' : null"
                v-for="event in addedEventInfo"
                :prepend-icon="event.icon"
                @click="
                  chooseEvent(eventEdit.eventData, event, { added: true })
                "
                >{{ event.name }}</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-card variant="outlined">
                <v-card-title>Mögliche Hotels</v-card-title>
                <v-card-text>
                  <v-chip
                    :color="eventEdit.eventData.is_senior ? 'success' : 'grey'"
                    :variant="
                      eventEdit.eventData.is_senior ? 'elevated' : 'outlined'
                    "
                    @click="
                      (eventEdit.eventData.is_senior = true),
                        checkHotelDropdown()
                    "
                    >Senior</v-chip
                  >
                  <v-chip
                    :color="!eventEdit.eventData.is_senior ? 'success' : null"
                    :variant="
                      !eventEdit.eventData.is_senior ? 'elevated' : 'outlined'
                    "
                    @click="
                      (eventEdit.eventData.is_senior = false),
                        checkHotelDropdown()
                    "
                    >Clerk</v-chip
                  >
                  <v-divider class="my-2"></v-divider>
                  <v-btn
                    :color="
                      hotel.id == eventEdit.eventData.hotel_id
                        ? 'success'
                        : null
                    "
                    size="small"
                    rounded="lg"
                    class="ma-1"
                    v-for="hotel in eventEdit.hotels"
                    @click="eventEdit.eventData.hotel_id = hotel.id"
                    :value="hotel.id"
                    >{{ hotel.short_name }}</v-btn
                  >
                  <v-col
                    v-if="eventEdit.shiftArray && eventEdit.shiftArray.length > 0 && eventEdit.firstHotelId != eventEdit.eventData.hotel_id"
                  >
                    <v-alert type="warning" title="Folgende Schichten werden nach dem Speichern
                          rausgenommen!">
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="3" v-for="shift in eventEdit.shiftArray">
                            <v-card
                              elevation="8"
                              :key="shift.id"
                              v-if="shift.employees"
                            >
                              <v-card-text>
                                <div>
                                  {{
                                    new Date(shift.date).toLocaleDateString(
                                      "de-DE",
                                    )
                                  }}
                                </div>
                                <div>{{ shift.employees.name }}</div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-alert>
                  </v-col>
                  
                </v-card-text>
                <v-card-actions>
                  <v-btn
                @click="switchShifts('clearShifts')"
                v-if="eventEdit.firstHotelId > 0"
                size="small"
                prepend-icon="mdi-close"
                rounded="xl"
                >Schichten leeren</v-btn
              >
                  <v-btn
                    :loading="eventEdit.switchLoading"
                    v-if="eventEdit.eventData.hotel_id && eventEdit.eventData.hotel_id != eventEdit.firstHotelId"
                    color="success"
                    @click="switchShifts()"
                    >Speichern</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="6">

            </v-col>
            <v-col cols="2">
              <v-divider vertical></v-divider>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
  >
    <div>{{ snackbar.data }}</div>
    <div>Erfolgreich synchronisiert</div>
    <template v-slot:actions>
      <v-btn variant="text" @click="snackbar.show = false"> Close </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
  >
    <div>{{ snackbar.data }}</div>
    <div v-if="snackbar.error">{{ snackbar.error }}</div>
    <div v-else>Erfolgreich synchronisiert</div>
    <template v-slot:actions>
      <v-btn variant="text" @click="snackbar.show = false"> Close </v-btn>
    </template>
  </v-snackbar>
  <v-dialog v-model="snackbar.errorShow" width="600px" persistent>
    <v-card>
      <v-alert type="error">
        <v-card-title> Fehler </v-card-title>
        <v-card-text>
          {{ snackbar.error }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="snackbar.errorShow = false"> Close </v-btn>
        </v-card-actions>
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { getISOWeek } from "date-fns";
</script>
<script>
export default {
  name: "Shifts",
  data() {
    return {
      preciseTable: false,
      tableLoading: false,
      today: new Date(),
      forwardDays: 6,
      backDays: 3,
      dayRange: 10,
      dateRange: [],
      hotels: [],
      cityHotels: {},
      startDate: new Date(),
      endDate: new Date(),
      eventEdit: {
        dialog: false,
        hotels: [],
        eventData: {},
      },
      weekdays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      employees: [],
      snackbar: {
        show: false,
        errorShow: false,
        color: "success",
        error: "",
        data: null,
        timeout: 4000,
      },
      eventFilter: null,
      tableLoading: false,
      headers: [],
      chosenCity: 1,
      chosenHotel: null,
      chosenLevel: 'Alle',
      chosenEmployee: null,
      search: null,
      cities: [],
      cityEmployees: [],
      addedEventInfo: [
        {
          name: "Verspätung",
          icon: "mdi-clock-alert",
          value: "is_late",
          color: "#000080",
        },
        {
          name: "Zusatzschicht",
          icon: "mdi-table-plus",
          value: "is_additional_shift",
          color: "#008000",
        },
        {
          name: "Schichtwechsel",
          icon: "mdi-swap-horizontal-bold",
          value: "has_changed_shift",
          color: "#cc99ff",
        },
        {
          name: "Einarbeitung",
          icon: "mdi-school",
          value: "is_training",
          color: "#ffff00",
          disabled: true,
        },
      ],
      events: [
        {
          name: "Frei",
          icon: "mdi-home",
          value: "is_free",
          disabled: true,
          color: "red",
        },
        {
          name: "Freiwunsch",
          icon: "mdi-calendar-heart",
          value: "is_free_wish",
          color: "#ffffff",
        },
        {
          name: "Urlaub",
          icon: "mdi-beach",
          value: "is_holiday",
          color: "red",
          disabled: true,
        },
        {
          name: "Aus dem Unternehmen",
          icon: "mdi-account-off",
          value: "is_out_of_company",
          color: "#999999",
          disabled: true,
        },
        {
          name: "Ausfall",
          icon: "mdi-hand-back-left",
          value: "is_sick",
          disabled: true,
          color: "#99ccff",
        },
        { name: "Büro", icon: "mdi-office-building", value: "is_office_shift" },
        {
          name: "Unbezahlter Urlaub",
          icon: "mdi-currency-usd-off",
          value: "is_unpaid_holiday",
          disabled: true,
          color: "red",
          editable: true,
        },
        {
          name: "Einarbeitung",
          icon: "mdi-school",
          value: "is_training",
          color: "#ffff00",
          disabled: true,
        },
      ],
    };
  },
  watch: {
    chosenCity(newCity) {
      this.chosenHotel = null;
      this.chosenLevel = "Alle";
      this.getEmployees(); // Runs AFTER chosenCity is updated
    },
    chosenHotel(newHotel) {
      this.getEmployees(); // Runs AFTER chosenHotel is updated
    },
    chosenLevel(newLevel) {
      this.getEmployees(); // Runs AFTER chosenLevel is updated
    },
    chosenEmployee(newEmployee) {
      this.getEmployees(); // Runs AFTER chosenHotel is updated
    },
    "eventEdit.eventData": {
      handler(newValue, oldValue) {
        if (newValue.hotel_id) {
          this.checkHotelData(newValue.hotel_id); // Aufruf der Methode zur Überprüfung der neuen hotel_id
        }
      },
      deep: true,
    },
    preciseTable(newPreciseTable) {
      if (newPreciseTable) {
        this.forwardDays = 27;
        this.backDays = 10;
        this.dayRange = 38;
        this.getNextMonday();
        this.getEmployees();
      } else {
        this.forwardDays = 6;
        this.backDays = 3;
        this.dayRange = 10;
        this.getNextMonday();
        this.getEmployees();
      }
    },
  },
  computed: {
    weekStartDate() {
      let date = new Date(this.startDate);
      date.setDate(this.startDate.getDate() + this.backDays);
      return date;
    },
    weekEndDate() {
      let date = new Date(this.endDate);
      date.setDate(this.endDate.getDate() - this.forwardDays + 6);
      return date;
    },
  },
  methods: {
    async checkHotelData(newValue) {
      if (newValue) {
        let queryObject = {
          select:
            "id,is_senior,hotel_id,employee_id,employees(name),hotels(name),date",
          table: "shifts",
          where: [
            { type: "eq", key: "hotel_id", value: newValue },
            { type: "gte", key: "employee_id", value: 0 },
            {
              type: "eq",
              key: "is_senior",
              value: this.eventEdit.eventData.is_senior,
            },
          ],
        };
        if (this.eventEdit.eventData.dateRange.length == 1) {
          queryObject.where.push({
            type: "eq",
            key: "date",
            value: this.eventEdit.eventData.dateRange[0].toISOString(),
          });
        }
        if (this.eventEdit.eventData.dateRange.length > 1) {
          let endDate = new Date(this.eventEdit.eventData.dateRange[1]);
          endDate.setHours(endDate.getHours() + 6);
          queryObject.where.push({
            type: "gte",
            key: "date",
            value: this.eventEdit.eventData.dateRange[0].toISOString(),
          });
          queryObject.where.push({
            type: "lte",
            key: "date",
            value: endDate.toISOString(),
          });
        }
        const response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        this.eventEdit.shiftArray = response.data;
      }
    },
    limitDateRange(newDates) {
      if (newDates.length > 2) {
        this.eventEdit.eventData.dateRange = [newDates[0], newDates[1]]; // Keep only the first two selected dates
      } else {
        this.eventEdit.eventData.dateRange = newDates; // Update as usual when selecting up to two dates
      }
    },
    async getCities() {
      const response = await this.$store.dispatch("getSupabaseEntity", {
        select: "id,city",
        table: "cities",
        where: [{ type: "is", key: "hotel_city", value: true }],
      });
      this.cities = response.data;
    },
    async getHotels() {
      const response = await this.$store.dispatch("getSupabaseEntity", {
        select: "id,name,short_name,city_id",
        table: "hotels",
        where: [{ type: "eq", key: "email", value: true }],
        order: { key: "name", ascending: true },
      });
      this.hotels = response.data;
      this.cityHotels = {
        "Alle Hotels": response.data,
      };
      response.data.forEach((hotel) => {
        if (!this.cityHotels[hotel.city_id]) {
          this.cityHotels[hotel.city_id] = [];
        }
        this.cityHotels[hotel.city_id].push(hotel);
      });
    },
    async switchShifts(type) {
      this.eventEdit.switchLoading = true;
      let eventData = this.eventEdit.eventData;
      let shiftArray = this.eventEdit.shiftArray;
      let dayRange = 1;
      if (eventData.dateRange.length > 1) {
        let startDate = new Date(eventData.dateRange[0]);
        startDate.setHours(startDate.getHours() + 6);
        let endDate = new Date(eventData.dateRange[1]);
        endDate.setHours(endDate.getHours() + 6);
        dayRange = Math.ceil(
          (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1,
        );
      }
      let upsertArray = [];
      for (var i = 0; i < dayRange; i++) {
        let payloadDate = new Date(eventData.dateRange[0]);
        payloadDate.setDate(payloadDate.getDate() + i);
        payloadDate = payloadDate.toISOString().split("T")[0];
        const activeShiftObject = {
          employee_id: eventData.employee_id,
          date: payloadDate,
          hotel_id: eventData.hotel_id,
          is_senior: eventData.is_senior,
          is_training: false,
          check_id: 1,
        };
        if (type === "clearShifts") {
          activeShiftObject.employee_id = null;
        }
        upsertArray.push(activeShiftObject);

        const activeSheetObject = {
          hotel_id: eventData.hotel_id,
          date: payloadDate,
          is_training: false,
          is_senior: eventData.is_senior,
          employee_id: activeShiftObject.employee_id,
        };
        if (type === "clearShifts") {
          activeSheetObject.oldEmpId = eventData.employee_id;
        }
        this.synchronizeWithSheets(activeSheetObject);

        if (shiftArray[i] && shiftArray[i].employee_id) {
          const deleteSheetObject = {
            hotel_id: eventData.hotel_id,
            date: payloadDate,
            is_training: false,
            is_senior: eventData.is_senior,
            employee_id: null,
            oldEmpId: shiftArray[i].employee_id,
          };

          this.synchronizeWithSheets(deleteSheetObject);
        }
        await this.$store.dispatch("upsertSupabaseEntity", {
          table: "shifts",
          payload: upsertArray,
        });
      }
      this.eventEdit.switchLoading = false;
      this.getEmployees();
    },
    async synchronizeWithSheets(shiftObject) {
      var url =
        "https://script.google.com/macros/s/AKfycbwWK4sIlvvd8GfF_aADVEX58TTvCG79DylrUReDOgRqqo9_Yp8Nd3O3JmGmZBooXAFl/exec?task=updateShiftPlan&secret_key=TEW567LPOVBN267";
      var options = {
        method: "POST",
        body: JSON.stringify(shiftObject),
      };
      try {
        var response = await fetch(url, options);
      }
      catch(err) {
        console.log(response.error);
        this.snackbar.color = "error";
        this.snackbar.error = response.error;
        this.snackbar.errorShow = true;
        this.snackbar.timeout = 100000;
      }
      
      response = await response.json();
      var employee = this.employees.find(
        (employee) => employee.id == response.payload.employee_id,
      );
      var date = new Date(response.payload.date).toLocaleDateString("de-DE");
      this.snackbar.data = employee
        ? employee.show_name
        : "Alter Mitarbeiter entfernt" + " am " + date;
      if (response.error) {
        console.log(response.error);
        this.snackbar.color = "error";
        this.snackbar.error = response.error;
        this.snackbar.errorShow = true;
        this.snackbar.timeout = 100000;
      } else {
        this.snackbar.color = "success";
        this.snackbar.timeout = 1000;
        this.snackbar.show = true;
      }
    },
    async getEmployees() {
      this.tableLoading = true;
      this.employees = [];
      let queryObject = {
        select:
          "id,first_name,last_name,status,shift_days,employee_data_matching(*,employee_data_keys(*)),shifts(*,hotels(id,name,short_name)),employee_date_events(*)",
        table: "employees",
        where: [
          { type: "neq", key: "status", value: "Inaktiv" },
          {
            type: "in",
            key: "employee_data_matching.key_id",
            value: [5, 6, 7, 30, 59],
          },
          {
            type: "gte",
            key: "shifts.date",
            value: this.startDate.toISOString(),
          },
          {
            type: "lte",
            key: "shifts.date",
            value: this.endDate.toISOString(),
          },
          {
            type: "gte",
            key: "employee_date_events.date",
            value: this.startDate.toISOString(),
          },
          {
            type: "lte",
            key: "employee_date_events.date",
            value: this.endDate.toISOString(),
          },
        ],
      };
      if (this.chosenCity) {
        queryObject.select =
          "id,first_name,last_name,status,shift_days,employees_hotels!inner(*,hotels!inner(id,city_id)),employee_data_matching(*,employee_data_keys(*)),shifts(*,hotels(id,name,short_name)),employee_date_events(*)";
        queryObject.where.push({
          type: "eq",
          key: "employees_hotels.hotels.city_id",
          value: this.chosenCity,
        });
      }
      if (this.chosenHotel) {
        queryObject.where.push({
          type: "eq",
          key: "employees_hotels.hotels.id",
          value: this.chosenHotel,
        });
      }

      if (this.chosenLevel && this.chosenLevel != "Alle") {
        if (this.chosenLevel == "Senior") {
          queryObject.where.push({
            type: "is",
            key: "employees_hotels.is_senior",
            value: true,
          });
        }
        else if (this.chosenLevel == "Clerk") {
          queryObject.where.push({
            type: "is",
            key: "employees_hotels.is_clerk",
            value: true,
          });
        }

      }

      const response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );

      response.data = response.data.filter((employee) => {
        return (
          !this.chosenEmployee ||
          JSON.stringify(employee)
            .toLowerCase()
            .includes(this.chosenEmployee.toLowerCase())
        );
      });

      response.data = response.data.filter((employee) => {
        return employee.employee_data_matching.some((data) => {
          return data.key_id === 6 && data.value_id === 2;
        });
      });
      response.data.forEach((employee) => {
        employee.show_name = employee.last_name + ", " + employee.first_name;
        employee.city = this.cities.find((city) => {
          return employee.employee_data_matching.some((data) => {
            return data.key_id === 5 && data.value_id === city.id;
          });
        }).city;
      });
      this.employees = [];
      response.data.forEach((employee) => {
        let employeeObject = {
          id: employee.id,
          show_name: employee.show_name,
          city: employee.city,
        };
        this.extractWeekDates(employee, employeeObject);
        if (this.eventFilter) {
          let pushElement = employee.employee_date_events.find((event) => {
            return event[this.eventFilter];
          });
          if (pushElement) {
            this.employees.push(employeeObject);
          }
        } else {
          this.employees.push(employeeObject);
        }
      });
      this.employees.sort((a, b) => {
        return a.show_name.localeCompare(b.show_name);
      });
      this.tableLoading = false;
    },
    async chooseEvent(eventData, event, added) {
      let searchArray = [];
      if (eventData.hotel_id > 0) {
        this.switchShifts("clearShifts");
      }
      eventData.event = event.name;
      eventData.icon = event.icon;
      if (added.added) {
        searchArray = this.addedEventInfo;
      } else {
        searchArray = this.events;
      }
      for (var i = 0; i < searchArray.length; i++) {
        let eventValue = searchArray[i].value;
        if (event.name === searchArray[i].name) {
          eventData[eventValue] = !eventData[eventValue];
        }
      }
      var upsertArray = [];

      let dayRange = 1;
      if (eventData.dateRange.length > 1) {
        let startDate = new Date(eventData.dateRange[0]);
        startDate.setHours(startDate.getHours() + 6);
        let endDate = new Date(eventData.dateRange[1]);
        endDate.setHours(endDate.getHours() + 6);
        dayRange = Math.ceil(
          (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1,
        );
      }

      for (var i = 0; i < dayRange; i++) {
        let payloadDate = new Date(eventData.dateRange[0]);
        payloadDate.setDate(payloadDate.getDate() + i);
        payloadDate = payloadDate.toISOString().split("T")[0];
        let payload = {
          employee_id: eventData.employee_id,
          date: payloadDate,
          hotel_id: eventData.hotel_id,
          is_free: eventData.is_free,
          is_free_wish: eventData.is_free_wish,
          is_holiday: eventData.is_holiday,
          is_sick: eventData.is_sick,
          is_office_shift: eventData.is_office_shift,
          is_unpaid_holiday: eventData.is_unpaid_holiday,
          is_late: eventData.is_late,
          is_additional_shift: eventData.is_additional_shift,
          is_training: eventData.is_training,
          has_changed_shift: eventData.has_changed_shift,
        };
        upsertArray.push(payload);

        let sheetPayloadObject = {
          employee_id: eventData.employee_id,
          date: payloadDate,
          is_employee_event: true,
          is_free_wish: eventData.is_free_wish,
          is_office_shift: eventData.is_office_shift,
        };
        this.synchronizeWithSheets(sheetPayloadObject);
      }

      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "employee_date_events",
        id: eventData.id,
        payload: upsertArray,
      });

      this.getEmployees();
    },
    extractWeekDates(employee, employeeObject) {
      const dates = [];
      const days = this.backDays + this.forwardDays;
      for (let i = 0; i <= days; i++) {
        let index = i + 1;
        let addedEventInfoArray = [];
        var date = new Date(this.startDate);
        date.setDate(this.startDate.getDate() + i);
        let pushedAlready = false;
        employee.shifts.forEach((shift) => {
          if (
            new Date(shift.date).toLocaleDateString() ===
            date.toLocaleDateString()
          ) {
            let eventName = shift.is_senior
              ? "sen/" + shift.hotels.short_name
              : "clerk/" + shift.hotels.short_name;
            employeeObject[index] = {
              date: date.toLocaleDateString("de-DE"),
              real_date: date,
              event: eventName,
              hotel_id: shift.hotels.id,
              icon: "mdi-domain",
              is_shift: true,
              is_senior: shift.is_senior,
            };
            pushedAlready = true;
          }
        });
        employee.employee_date_events.forEach((event) => {
          if (
            new Date(event.date).toLocaleDateString() ===
            date.toLocaleDateString()
          ) {
            let eventName = null;
            let icon = null;
            let value = null;
            let color = null;
            if (event.is_free) {
              eventName = "Frei";
              icon = "mdi-home";
              value = "is_free";
              color = "red";
            } else if (event.is_free_wish) {
              eventName = "Freiwunsch";
              icon = "mdi-calendar-heart";
              value = "is_free_wish";
            } else if (event.is_holiday) {
              eventName = "Urlaub";
              icon = "mdi-beach";
              color = "red";
              value = "is_holiday";
            } else if (event.is_sick) {
              eventName = "Ausfall";
              icon = "mdi-hand-back-left";
              value = "is_sick";
              color = "#99ccff";
            } else if (event.is_office_shift) {
              eventName = "Büro";
              icon = "mdi-office-building";
              value = "is_office_shift";
            } else if (event.is_unpaid_holiday) {
              eventName = "Urlaub";
              icon = "mdi-currency-usd-off";
              value = "is_unpaid_holiday";
              color = "red";
            } else if (event.is_out_of_company) {
              eventName = "Aus dem Unternehmen";
              icon = "mdi-account-off";
              value = "is_out_of_company";
              color = "#999999";
            }
            if (eventName && !pushedAlready) {
              employeeObject[index] = {
                date: date.toLocaleDateString(),
                event: eventName,
                icon: icon,
                value: value,
                color: color,
              };
              pushedAlready = true;
            }
            for (var i = 0; i < this.addedEventInfo.length; i++) {
              let eventValue = this.addedEventInfo[i].value;
              if (event[eventValue]) {
                addedEventInfoArray.push(this.addedEventInfo[i]);
              }
            }
          }
          if (employeeObject[index]) {
            employeeObject[index].addedEvents = addedEventInfoArray;
          }
        });
        if (!pushedAlready) {
          employeeObject[index] = {
            date: date.toLocaleDateString(),
            event: "Offen",
            icon: null,
          };
        }
      }
      return dates;
    },
    async editEvent(employee, value, n) {
      this.eventEdit.dialog = true;
      this.eventEdit.shiftArray = [];
      this.eventEdit.firstHotelId = value.hotel_id;
      const [day, month, year] = value.date.split(".");

      // Format the date into YYYY-MM-DD
      const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0",
      )}`;

      let dateRangePart = new Date(formattedDate);
      dateRangePart.setHours(15);

      if (value.is_senior == undefined && this.chosenLevel != "Alle") {
        value.is_senior = this.chosenLevel == "Senior" ? true : false;
      }
      this.eventEdit.eventData = {
        employee_id: employee.id,
        name: employee.show_name,
        date: value.date,
        is_senior:
          value.is_senior || value.is_senior == undefined ? true : false,
        formattedDate: formattedDate,
        dateRange: [dateRangePart],
        event: value.event,
        hotel_id: value.hotel_id,
        icon: value.icon,
        hotels: [],
      };
      await this.checkHotelDropdown();
      if (value.addedEvents) {
        value.addedEvents.forEach((event) => {
          this.eventEdit.eventData[event.value] = true;
        });
      }
      this.eventEdit.eventData[value.value] = true;
    },
    async changeDays(type) {
      if (type === "plus") {
        this.today.setDate(this.today.getDate() + 7);
      } else {
        this.today.setDate(this.today.getDate() - 7);
      }
      this.startDate = new Date(this.today);
      this.endDate = new Date(this.today);
      this.startDate.setDate(this.today.getDate() - this.backDays);
      this.endDate.setDate(this.today.getDate() + this.forwardDays);
      this.addHeaders();
      this.getEmployees();
    },
    async checkHotelDropdown() {
      const value = this.eventEdit.eventData;
      let queryObject = {
        select: "id,hotels!inner(id,name,short_name)",
        table: "employees_hotels",
        where: [
          { type: "eq", key: "employee_id", value: value.employee_id },
          { type: "is", key: "hotels.email", value: true },
        ],
      };
      if (value.is_senior == false) {
        queryObject.where.push({ type: "eq", key: "is_clerk", value: true });
      } else {
        queryObject.where.push({ type: "eq", key: "is_senior", value: true });
      }
      const response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.eventEdit.hotels = response.data.map((hotel) => {
        return {
          id: hotel.hotels.id,
          name: hotel.hotels.name,
          short_name: hotel.hotels.short_name,
        };
      });
      let searchHotel = this.eventEdit.hotels.find((hotel) => {
        if (hotel.id === value.hotel_id) {
          return hotel;
        }
      });
      if (!searchHotel) {
        this.eventEdit.eventData.hotel_id = null;
      }
    },
    addHeaders() {
      this.headers = [
        {
          title: "Name",
          align: "start",
          sortable: false,
          value: "show_name",
        },
      ];
      const days = this.backDays + this.forwardDays;
      for (let i = 0; i <= days; i++) {
        var date = new Date(this.startDate);
        date.setDate(this.startDate.getDate() + i);
        const dateOptions = {
          weekday: "short",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        };
        this.headers.push({
          title: date.toLocaleDateString("de-DE", dateOptions),
          date: date,
          value: (i + 1).toString(),
          align: "center",
        });
      }
    },
    // Your methods go here
    getNextMonday() {
      this.today = new Date();
      const currentDay = this.today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
      const daysUntilNextMonday = (8 - currentDay) % 7 || 7; // Calculate days until next Monday
      this.today.setDate(this.today.getDate() + daysUntilNextMonday); // Add days to get to the next Monday
      this.startDate = new Date(this.today);
      this.endDate = new Date(this.today);
      this.startDate.setDate(this.today.getDate() - this.backDays);
      this.endDate.setDate(this.today.getDate() + this.forwardDays);
      this.addHeaders();
    },
  },

  mounted() {
    this.getCities();
    this.getHotels();
    if (this.preciseTable) {
      this.forwardDays = 27;
      this.backDays = 10;
      this.dayRange = 38;
      this.getNextMonday();
      this.getEmployees();
    } else {
      this.forwardDays = 6;
      this.backDays = 3;
      this.dayRange = 10;
      this.getNextMonday();
      this.getEmployees();
    }
    this.getNextMonday();
    this.getEmployees();
    // Code to run when the component is mounted goes here
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 2px;
  text-align: center;
}

:deep(.v-data-table-virtual th),
:deep(.v-data-table-virtual td) {
  border: 1px solid black;
}

.tableFixHead {
  overflow: auto;
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.tableFixHead thead th {
  position: sticky;
  top: -2px;
  z-index: 1;
  background-color: white;
}
.sticky-col {
  position: sticky;
  left: -20px;
  border: 4px solid black;
  z-index: 1001; /* Höher als der Header */
  background-color: white; /* Sonst wird es transparent */
  box-shadow: 4px 0 0 black;
}
/* Your component-specific styles go here */
</style>
