<template>
  <v-row class="mx-2 my-4">
    <v-col cols="12" align="center">
      <v-btn
        color="success"
        @click="
          hotelObject = { emails: [{ value: null }] };
          $store.state.hotelView.drawer = true;
        "
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-col>
    <v-data-table
      @click:row="openHotel"
      :loading="loading"
      :items="hotels"
      :headers="headers"
      :search="$store.state.hotelView.search"
    >
      <template v-slot:item.email="{ item }">
        <v-switch readonly color="success" v-model="item.email"></v-switch>
      </template>
      <template v-slot:item.employees="{ item }">
        <template v-for="employee in item.employees">
          <v-tooltip
            :text="`${employee.count} Schichten ingesamt, ${employee.senior_count} Senior und ${employee.clerk_count} Clerk`"
          >
            <template v-slot:activator="{ props }">
              <v-chip
                class="ma-1"
                size="small"
                :color="setColor(employee.count)"
                v-bind="props"
                @click.stop="
                  $router.push({ path: '/Mitarbeiter/' + employee.id })
                "
              >
                {{ employee.name }}
                <v-rating
                  readonly
                  class="ml-1 mb-1"
                  density="compact"
                  size="x-small"
                  length="5"
                  v-model="employee.level"
                ></v-rating>
                <v-chip size="x-small" v-if="employee.is_senior">Senior</v-chip>
                <v-chip size="x-small" v-if="employee.is_clerk">Clerk</v-chip>
                <v-chip
                  size="x-small"
                  variant="elevated"
                  color="error"
                  v-if="employee.is_blocked"
                  >Gesperrt</v-chip
                >
              </v-chip>
            </template>
          </v-tooltip>
        </template>
      </template>
    </v-data-table>
  </v-row>
  <v-navigation-drawer
    v-model="$store.state.hotelView.drawer"
    temporary
    location="right"
    width="1000"
    class="pa-4"
    color="grey-lighten-3"
  >
    <Edit :hotelObject="hotelObject"></Edit>
  </v-navigation-drawer>
</template>

<script setup>
import Edit from "@/components/Hotels/Edit.vue";
import { mapState } from "vuex";
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      hotels: [],
      hotelObject: {},
      search: null,
      headers: [
        { title: "Name", value: "name" },
        { title: "Kürzel", value: "short_name" },
        { title: "Ort", value: "cities.city" },
        { title: "Email senden", value: "email" },
      ],
    };
  },
  computed: {
    ...mapState({
      hotelView: (state) => state.hotelView,
    }),
  },
  watch: {
    hotelView: {
      async handler(newValue, oldValue) {
        this.getHotels();
      },
      deep: true,
      async: true,
    },
  },
  methods: {
    checkFilter() {
      return true;
    },
    async getHotels() {
      this.loading = true;
      if (!this.$store.state.userInfo.id) {
        await this.$store.dispatch("sleep", 200);
      }
      var userRights = await this.$store.dispatch("getSupabaseEntity", {
        select: "*,public_users(id,employees(id))",
        table: "public_user_rights",
        where: [
          {
            type: "eq",
            key: "public_users.id",
            value: this.$store.state.userInfo.id,
          },
        ],
      });

      var isOfficeUser = userRights.data[0].is_office;
      if (
        isOfficeUser &&
        !this.headers.find((item) => item.value == "employees")
      ) {
        this.headers.push({ title: "Mitarbeiter", value: "employees" });
      } else if (!isOfficeUser) {
        var employeeId = userRights.data[0].public_users.employees[0].id;
      }
      var shiftLimit = new Date();
      shiftLimit.setDate(shiftLimit.getDate() - 180);
      var queryObject = {
        select: "*,employees_hotels(*),shifts(*,employees(name,status))",
        table: "hotels",
        order: { key: "name", ascending: true },
        where: [
          {
            type: "gte",
            key: "shifts.date",
            value: shiftLimit.toISOString(),
          },
          {
            type: "gt",
            key: "shifts.employee_id",
            value: 0,
          },
        ],
      };
      if (this.$store.state.hotelView.email) {
        queryObject.where.push({
          type: "is",
          key: "email",
          value: true,
        });
      }
      if (this.$store.state.hotelView.locations.length > 0) {
        queryObject.where.push({
          type: "in",
          key: "city_id",
          value: this.$store.state.hotelView.locations,
        });
      }
      if (this.$store.state.hotelView.employees.length > 0) {
        queryObject.where.push({
          type: "in",
          key: "employees_hotels.employees.id",
          value: this.$store.state.hotelView.employees,
        });
        queryObject.select =
          "*,cities(*),employees_hotels(*,employees!inner(*)),shifts(*,employees(name,status))";
      } else if (employeeId) {
        queryObject.where.push({
          type: "eq",
          key: "employees_hotels.employee_id",
          value: employeeId,
        });
      } else {
        queryObject.select =
          "*,cities(*),employees_hotels(*,employees(*)),shifts(*,employees(name,status))";
      }

      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach((hotel) => {
        var employeeRecord = {};
        var employeeArray = [];
        hotel.employees_hotels.forEach((employee) => {
          if (
            !employeeRecord[employee.employee_id] &&
            employee.employees.status == "Aktiv"
          ) {
            employeeRecord[employee.employee_id] = {
              name:
                employee.employees.first_name +
                " " +
                employee.employees.last_name,
              count: 0,
              id: employee.employee_id,
              is_senior: employee.is_senior,
              is_clerk: employee.is_clerk,
              is_blocked: employee.is_blocked,
              senior_count: 0,
              clerk_count: 0,
              training_count: 0,
            };
          }
        });
        hotel.shifts.forEach((shift) => {
          if (shift.employees.status == "Aktiv" && !shift.is_training) {
            if (!employeeRecord[shift.employee_id]) {
              employeeRecord[shift.employee_id] = {
                id: shift.employee_id,
                name: shift.employees.name,
                count: 0,
                senior_count: 0,
                clerk_count: 0,
                training_count: 0,
              };
            }
            if (shift.is_senior) {
              employeeRecord[shift.employee_id].senior_count++;
              employeeRecord[shift.employee_id].count++;
            } else if (shift.is_senior == false && shift.is_training == false) {
              employeeRecord[shift.employee_id].clerk_count++;
              employeeRecord[shift.employee_id].count++;
            } else if (shift.is_senior == false && shift.is_training == true) {
              employeeRecord[shift.employee_id].training_count++;
            }
          }
        });
        employeeRecord = Object.values(employeeRecord);
        employeeRecord.sort((a, b) => b.count - a.count);
        employeeRecord.forEach((employee) => {
          if (employee.count > 40) {
            employee.level = 5;
          } else if (employee.count > 30) {
            employee.level = 4;
          } else if (employee.count > 20) {
            employee.level = 3;
          } else if (employee.count > 10) {
            employee.level = 2;
          } else if (employee.count > 0) {
            employee.level = 1;
          } else {
            employee.level = 0;
          }
        });
        hotel.employees = employeeRecord;
      });
      response.data.forEach((hotel) => {
        delete hotel.shifts;
        delete hotel.employees_hotels;
        delete hotel.performance_record_template_clerk;
        delete hotel.performance_record_template_senior;
      });
      this.hotels = response.data;
      this.loading = false;
    },
    setColor(count) {
      if (count > 20) {
        return "green-darken-2";
      } else if (count > 10) {
        return "orange-darken-2";
      } else {
        return "grey-darken-2";
      }
    },
    openHotel(event, row) {
      var hotel = row.item;
      this.hotelObject = JSON.parse(JSON.stringify(hotel));
      if (this.hotelObject.emails) {
        this.hotelObject.emails.forEach(function (item, index, arr) {
          arr[index] = { value: item };
        });
      } else {
        this.hotelObject.emails = [{ value: null }];
      }

      this.$router.push({ path: "/Hotels/" + hotel.id });
    },
  },
  async mounted() {
    await this.getHotels();
  },
};
</script>
