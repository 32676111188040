<template>
    <h1 class="ma-2" align="center">Accessoires</h1>
    <v-card>
      <v-card-text class="mt-3">
          <v-dialog v-model="protocolDialog">
            <v-card>
                <v-card-text>
                    <AccessoryProtocol :close-dialog="() => protocolDialog = false"></AccessoryProtocol>
                </v-card-text>
            </v-card>
          </v-dialog>
        <v-row v-for="(accessory, index) in accessoryItems" class="my-n6">
          
            <v-col cols="8">
            <v-text-field
              variant="outlined"
              density="compact"
              v-model="accessory.name"
              label="Accessoire"
            ></v-text-field>
            
          </v-col>
          <v-col cols="4">
            <v-text-field
              variant="outlined"
              density="compact"
              type="number"
              @click:append="deleteAccessory(accessory, index)"
              v-model="accessory.stock_count"
              label="Anzahl"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      
      <v-col align="center">
        <v-btn @click="accessoryItems.push({ name: null })">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn @click="saveAccessorys()" class="ml-2" :loading="loading">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-col>
      <v-col align="center" cols="12" class="mb-5">
            <v-btn @click="protocolDialog = true" color="success" append-icon="mdi-plus">Protokoll erstellen</v-btn>
          </v-col>
          
      </v-card>
      
  </template>
  
  <script setup>
  import draggable from "vuedraggable";
  import AccessoryProtocol from "./MaterialManagement/AccessoryProtocol.vue";
  </script>
  <script>
  export default {
    
    data() {
      return {
        loading: false,
        accessoryItems: [],
        protocolDialog: false,
        
      };
    },
    
    methods: {
      async getAccessoryItems() {
        var queryObject = {
          table: "accessory_items",
          select: "*",
          order: {
            key: "name",
            ascending: true,
          },
        };
        var response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        this.accessoryItems = response.data;
      },
      
      async deleteAccessory(accessory, index) {
        console.log(accessory)
        if (accessory.id) {
          await this.$store.dispatch("deleteSupabaseEntity", {
            id: accessory.id,
            table: "accessory_items",
          });
        }
        this.accessoryItems.splice(index, 1);
      },
      async saveAccessorys() {
        var table = "accessory_items";
        var payload = this.accessoryItems;
        this.loading = true;
        var payload = JSON.parse(JSON.stringify(payload));
        var upsertArray = [];
        for (var i = 0; i < payload.length; i++) {
          if (payload[i].id) {
            upsertArray.push(payload[i]);
          } else {
            var queryObject = {
              table: table,
              payload: payload[i],
            };
            await this.$store.dispatch("createSupabaseEntity", queryObject);
          }
        }
        var queryObject = {
          table: table,
          payload: upsertArray,
        };
        await this.$store.dispatch("upsertSupabaseEntity", queryObject);
        await this.getAccessoryItems();
        this.loading = false;
      },
    },
    async mounted() {
      this.getAccessoryItems();
    },
    
  };
  </script>
  